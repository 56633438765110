const btnElement = document.getElementById('btn_modal_cards_detalhes-da-oferta');
const sectionElement = document.getElementById('modalSection');
const overlayContainer = document.getElementById('overlayModal');
const buttonClosed = document.getElementById('btn_closed_modal-offers');

function toggleModal() {
	if (sectionElement.hasAttribute('open')) {
		sectionElement.removeAttribute('open');
		sectionElement.setAttribute('close', '');
		overlayContainer.removeAttribute('open');
		overlayContainer.setAttribute('close', '');
		document.body.style.position = '';
	} else {
		sectionElement.removeAttribute('close');
		sectionElement.setAttribute('open', '');
		overlayContainer.removeAttribute('close');
		overlayContainer.setAttribute('open', '');
		document.body.style.position = 'fixed';
	}
}

btnElement?.addEventListener('click', toggleModal);
buttonClosed?.addEventListener('click', toggleModal);
overlayContainer?.addEventListener('click', toggleModal);
