const options = { root: null, rootMargin: '0px', threshold: 0.5 };
function pushDataLayer(data) {
  dataLayer.push({
    ...data
  });
}

function observerElement(element, data) {
  if (element) {
    const observer = new IntersectionObserver((entries) => {
      entries?.forEach((entry) => {
        if (entry.isIntersecting) {
          pushDataLayer(data);
        }
      });
    }, options);
    observer.observe(element);
  }
}

function generateEventClick(element, eventAction) {
  if (!element) return;
  const eventData = {
    event: 'internet_event',
    eventAction: eventAction  
  };
  element.addEventListener('click', () => {
    pushDataLayer(eventData);
  });
}

function addEventListenerToButton(element, eventData) {
  if (element) {
    element.addEventListener('click', () => {
      pushDataLayer(eventData);
    });
  }
}

function generateSelectEvent(element, contentType) {
  if (element) {
    const contentData = {
      event: 'select_content',
      content_type: contentType,
      content_id: element?.id
    };
    element.addEventListener('click', () => {
      pushDataLayer(contentData);
    });
  }
}

function generateViewItemList(element, itemListName, item) {
  if (element) {
    return {
      event: 'view_item_list',
      item_list_id: element.id,
      item_list_name: itemListName,
      items: [item]
    };
  }
}

function generateSelectItemList(element, itemListName, item) {
  if (element) {
    return {
      event: 'select_item',
      item_list_id: element.id,
      item_list_name: itemListName,
      items: [item]
    };
  }
}

function observerElementNoID(element, cardData, item) {
  if (element) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          cardData.items.push(item);
          pushDataLayer(cardData);
        }
      });
    }, options);
    observer.observe(element);
  }
}



const navMenu3nivelALl = document.querySelectorAll('#internalNavigationBar a');
navMenu3nivelALl.forEach((item) => {
  const linkUrl = new URL(item.href);
  const linkHostName = linkUrl.hostname;
  const pageHostName = window.location.host;
  const linkDomain = `${linkHostName}${linkUrl.pathname}`;

  function getOutboundInfo() {
    return linkHostName === pageHostName;
  }

  const outbound = getOutboundInfo();

  item.addEventListener('click', () => {
    pushDataLayer({
      event: 'header_click',
      page_location: window.location.href,
      page_path: window.location.pathname,
      link_classes: item.className,
      link_domain: linkDomain,
      link_id: `btn_header_${item.textContent.replaceAll(' ', '_')}`,
      link_url: item.href,
      outbound: outbound
    });
  });
});

// Banner Events
const bannersAll = document.querySelectorAll('.section-banner__item');
bannersAll.forEach((item) => {
  if (item.hasAttribute('data-context-gtm')) {
    const dataGtmOnTAg = item.getAttribute('data-context-gtm').split('||');
    const dataGTM = {
      creative_name: dataGtmOnTAg[0],
      creative_slot: dataGtmOnTAg[0],
      promotion_id: dataGtmOnTAg[1],
      promotion_name: dataGtmOnTAg[2].trim(),
      items: {
        item_id: dataGtmOnTAg[3],
        item_name: dataGtmOnTAg[2].trim(),
        item_brand: dataGtmOnTAg[4],
        item_category: dataGtmOnTAg[4],
        price: dataGtmOnTAg[5],
        quantity: 1
      }
    };

    observerElement(item, { event: 'view_promotion', ...dataGTM });

    const bannerClicavel = item.querySelectorAll('.section-banner__mask-click');
    bannerClicavel?.forEach((item) => {
      item.addEventListener('click', () => {
        pushDataLayer({ event: 'select_promotion', ...dataGTM });
        pushDataLayer({
          event: 'internet_event',
          eventAction: dataGtmOnTAg[0]
        });
      });
    });
    const btnConsult = item.querySelectorAll('[data-gtm-id="btn_click_consult"]');
    btnConsult?.forEach((item) => {
      addEventListenerToButton(item, { event: 'internet_event', eventAction: 'banner_bora-bora_btn_consultar-disponibilidade' });
      item.addEventListener('click', () => {
        pushDataLayer({ event: 'select_promotion', ...dataGTM });
      });
    });
   


  }
});



// Section banner

// Section offers-cards
generateEventClick(document.getElementById('btn_nav_cards-fibra'), 'aba_oi_fibra');
generateEventClick(document.getElementById('btn_nav_cards-fibra-globoplay'), 'aba_com_globoplay');
generateEventClick(document.getElementById('btn_nav_cards-fibra-wifi'), 'aba_com_wifi_ampliado');

// Section notices-caroussel
generateEventClick(document.getElementById('btn_link_monte_seu_plano'), 'btn_monte_seu_plano');

// Section inner-banner
generateEventClick(document.getElementById('btn_link_saiba-mais-promo'), 'btn_dobra_saber_mais_promo');
generateEventClick(document.getElementById('btn_link_indicar-e-ganhar'), 'btn_dobra_indicar_e_ganhar');

generateEventClick(document.getElementById('btn_link_disponibilidade-por-website'), 'btn_dobra_consultar_disponibilidade');
generateEventClick(document.getElementById('btn_link_mobile_disponibilidade-por-website'), 'btn_dobra_consultar_disponibilidade');

generateEventClick(document.getElementById('btn_link_start-chat'), 'btn_dobra_iniciar_conversa_whatsapp');
generateEventClick(document.getElementById('btn_link_mobile_whatsapp'), 'btn_dobra_iniciar_conversa_whatsapp');

generateEventClick(document.getElementById('btn_link_por-telefone'), 'btn_dobra_telefone');
generateEventClick(document.getElementById('btn_link_mobile_numberLink'), 'btn_dobra_telefone');

// Section fibra-benefits
generateEventClick(document.getElementById('btn_link_fibra-benef-cta-consult'), 'btn_dobra_melhores_planos_consultar_disp');

// Section client-services
generateEventClick(document.getElementById('btn_link_desktop_help-center'), 'btn_atendimento_central_ajuda');
generateEventClick(document.getElementById('btn_link_mobile_help-center'), 'btn_atendimento_central_ajuda');
generateEventClick(document.getElementById('btn_link_desktop_service-channel'), 'btn_atendimento_canais');
generateEventClick(document.getElementById('btn_link_mobile_service-channel'), 'btn_atendimento_canais');
generateEventClick(document.getElementById('btn_service_atendimento-seg-via'), 'btn_atendimento_segunda_via');
generateEventClick(document.getElementById('btn_service_suport-tecnico'), 'btn_atendimento_suporte');
generateEventClick(document.getElementById('btn_service_manage-plan-app'), 'btn_atendimento_gerenciar_pelo_app');
generateEventClick(document.getElementById('btn_service_solve_whatsapp'), 'btn_atendimento_whatsapp');

// Section blog-oi
generateEventClick(document.getElementById('btn_link_conhecer-blog'), 'btn_conhecer_o_blog');

// Section Faq
generateEventClick(document.getElementById('btn_ver_mais_duvidas'), 'btn_ver_mais_duvidas');

// Section blog
generateSelectEvent(document.getElementById('btn_link_blog-casa-inteligente'), 'blog');
generateSelectEvent(document.getElementById('btn_link_blog-tecnologia'), 'blog');
generateSelectEvent(document.getElementById('btn_link_blog-internet'), 'blog');

// Section Faq
const faqViewItemList = generateViewItemList(document.getElementById('oi_fibra_duvidas'), 'oi_fibra_duvidas', {
  item_id: "cobertura",
  item_name: "cobertura",
  item_brand: "adicionais",
  item_category: "adicionais",
  price: null,
  quantity: 1
});

observerElement(document.getElementById('oi_fibra_duvidas'), faqViewItemList);

const faqDetailAll = document.querySelectorAll('#faq-main details');
faqDetailAll.forEach((item) => {
  const faqDetailData = {
    event: 'select_item',
    item_list_id: "oi_fibra_duvidas",
    item_list_name: "oi_fibra_duvidas",
    item_id: "cobertura",
    item_name: item.querySelector('summary').textContent.trim(),
    item_brand: "cobertura",
    item_category: "cobertura",
    price: null,
    quantity: 1
  };

  item.addEventListener('click', () => {
    pushDataLayer(faqDetailData);
  });
});



const observedCards = [];
const cardOffersAll = document.querySelectorAll('.offers-cards__card');

const cardData = {
  event: 'view_item_list',
  item_list_id: 'oi_fibra',
  item_list_name: 'oi_fibra',
  items: []
};

function observerElementCards(element) {
  if (element) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const dataGtmOnTAg = entry.target.getAttribute('data-context-gtm').split('||');
          const newItem = {
            item_id: dataGtmOnTAg[0],
            item_name: dataGtmOnTAg[1],
            item_brand: dataGtmOnTAg[2],
            item_category: dataGtmOnTAg[2],
            price: dataGtmOnTAg[4],
            quantity: 1
          };

          observedCards.push(newItem);

          cardData.items = [...observedCards];

        }
      });
    }, { threshold: 0.1 });
    observer.observe(element);
  }
}

cardOffersAll.forEach(card => {
  observerElementCards(card);
});

observerElement(document.querySelector('.offers-cards__cards'), {
  event: 'view_item_list',
  item_list_id: 'oi_fibra',
  item_list_name: 'oi_fibra',
  items: observedCards 
});
